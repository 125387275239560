import { SimpleGrid } from '@efishery/onefish';

import { Card, Description } from 'components';

type AddressProps = {
  province?: string;
  city?: string;
  address?: string;
};

export const Address = ({ province, city, address }: AddressProps) => {
  return (
    <Card p="4">
      <SimpleGrid columns={1} spacing="2">
        <SimpleGrid columns={2} spacing="3">
          <Description
            name="Provinsi"
            value={province}
            direction="vertical"
          />
          <Description
            name="Kota"
            value={city}
            direction="vertical"
            format="startcase"
          />
        </SimpleGrid>
        <Description
          name="Alamat"
          value={address}
          direction="vertical"
          format="startcase"
        />
      </SimpleGrid>
    </Card>
  );
};
