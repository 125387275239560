import { ErrorComponent } from '@refinedev/chakra-ui';
import { Authenticated } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { Outlet, Route, Routes } from 'react-router-dom';

import { DeliveryEdit, DeliveryList } from 'pages/delivery';
import { FinishList, FinishShow } from 'pages/finish';
import { ProfileList } from 'pages/profile';
import { Redirect } from 'pages/redirect';
import { MobileLayout } from './components';
import { Login } from './pages/login';
import { PickupEdit, PickupList } from './pages/pickup';

export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to="/login" />}>
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        </Authenticated>
      }
    >
      <Route index element={<NavigateToResource resource="profile" />} />
      <Route path="/pickup">
        <Route index element={<PickupList />} />
        <Route path="edit/:id" element={<PickupEdit />} />
      </Route>
      <Route path="/profile">
        <Route index element={<ProfileList />} />
      </Route>
      <Route path="/finish">
        <Route index element={<FinishList />} />
        <Route path="show/:id" element={<FinishShow />} />
      </Route>
      <Route path="/delivery">
        <Route index element={<DeliveryList />} />
        <Route path="edit/:id" element={<DeliveryEdit />} />
      </Route>
      <Route path="*" element={<ErrorComponent />} />
    </Route>
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource />
        </Authenticated>
      }
    >
      <Route path="/login" element={<Login />} />
      <Route path="/redirect" element={<Redirect />} />
    </Route>
  </Routes>
);
