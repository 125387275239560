import { Center, Heading, Image, Text, VStack } from '@efishery/onefish';
import { FlagAccess } from 'components/FlagAccess';
import { FLAG_ID } from 'configs';
import { FC, ReactNode } from 'react';

interface MaintenanceProps {
  children: ReactNode;
}

const Maintenance: FC<MaintenanceProps> = ({ children }) => {
  return (
    <FlagAccess
      variantKey="on"
      evaluation={{ flagID: FLAG_ID.ENABLE_MAINTENANCE_DRIVER_APP }}
      fallback={<>{children}</>}
    >
      <Center h="100vh">
        <VStack spacing="4">
          <Heading as="h1" size="xl" mb="3">
            Maintenance
          </Heading>
          <Image src="/maintenance.png" alt="Maintenance" />
          <Text>Maaf, Sedang Ada Pemeliharaan di Sistem Kami. </Text>
          <Text>Harap tunggu sebentar ya.</Text>
        </VStack>
      </Center>
    </FlagAccess>
  );
};

export default Maintenance;
