import { useContext, useEffect, useState } from 'react';
import { FlagContext, type CanParams } from 'contexts';

export type UseFlagParams = CanParams;

export const useFlag = (params: CanParams) => {
  const [isOn, setIsOn] = useState(true);
  const { can, isLoading } = useContext(FlagContext);

  useEffect(() => {
    can?.(params).then(isOn => {
      setIsOn(isOn);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, data: isOn };
};
