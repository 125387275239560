import {
  Flex,
  FlexboxProps,
  HStack,
  Text,
  TypographyProps,
} from '@efishery/onefish';
import { capitalize, currency, startCase, thousandSeparator } from 'utils';

type Direction = 'horizontal' | 'vertical';

type Format = 'startcase' | 'capitalize' | 'currency' | 'thousand-separator';

type DescriptionProps = {
  name: string;
  value?: string | number | null;
  direction?: Direction;
  important?: boolean;
  format?: Format;
  unit?: string;
  children?: React.ReactNode;
};

const flexProps: {
  [k in Direction]: FlexboxProps;
} = {
  horizontal: { flexDir: 'row', justifyContent: 'space-between', gap: '4' },
  vertical: { flexDir: 'column', justifyContent: 'flex-start' },
};

const textAlign: {
  [k in Direction]: TypographyProps['textAlign'];
} = {
  horizontal: 'right',
  vertical: 'left',
};

function formattedValue(args: Pick<DescriptionProps, 'value' | 'format'>) {
  const { value, format } = args;

  if (!value) return '-';

  switch (format) {
    case 'capitalize':
      return capitalize(value?.toString());
    case 'startcase':
      return startCase(value?.toString());
    case 'currency':
      return currency(Number(value));
    case 'thousand-separator':
      return thousandSeparator(Number(value));
    default:
      return value;
  }
}

export const Description = ({
  name,
  value,
  direction = 'horizontal',
  important = false,
  format,
  unit,
  children,
}: DescriptionProps) => {
  return (
    <Flex {...flexProps[direction]}>
      <Text
        fontSize="sm"
        color="grey.500"
        fontWeight={important ? 'semibold' : 'normal'}
      >
        {name}
      </Text>
      {children ?? (
        <HStack spacing="1">
          <Text
            fontSize="sm"
            textAlign={textAlign[direction]}
            fontWeight={important ? 'semibold' : 'normal'}
          >
            {formattedValue({ value, format })}
          </Text>
          {Boolean(unit) && (
            <Text as="span" fontSize="sm">
              {unit}
            </Text>
          )}
        </HStack>
      )}
    </Flex>
  );
};
