import React from 'react';
import { Box, BoxProps, Flex, Text, VStack } from '@efishery/onefish';
import { useNavigation, useResource } from '@refinedev/core';

type NavBarPosition = 'top' | 'bottom';

type NavBarProps = {
  position?: NavBarPosition;
};

export const NavBar = ({ position = 'bottom' }: NavBarProps) => {
  const { resources, identifier } = useResource();
  const { list } = useNavigation();

  const renderNavs: React.ReactElement[] = [];
  for (const resource of resources) {
    if (resource?.meta?.bottomNav) {
      renderNavs.push(
        <NavItem
          key={resource.name}
          title={resource?.meta?.label || ''}
          icon={resource?.meta?.icon as JSX.Element}
          position={position}
          active={resource.name === identifier}
          onClick={() => list(resource.name)}
        />,
      );
    }
  }

  return (
    <Box
      w="full"
      height="60px"
      display="flex"
      bg="white"
      position="sticky"
      bottom="0"
      zIndex="1"
    >
      <Box w="full">
        <Flex justifyContent="space-around" data-testid="box_navigation">{renderNavs}</Flex>
      </Box>
    </Box>
  );
};

type NavItemProps = {
  title: string;
  icon: JSX.Element;
  position: NavBarPosition;
  active: boolean;
  onClick: () => void;
};

const NavItem = ({
  title,
  icon,
  position,
  active = false,
  onClick,
}: NavItemProps) => {
  const borderProps: BoxProps = {};

  switch (position) {
    case 'top':
      borderProps.borderBottom = '2px';
      borderProps.borderBottomColor = active ? 'brand.500' : 'gray.200';
      break;
    case 'bottom':
      borderProps.borderTop = '2px';
      borderProps.borderTopColor = active ? 'brand.500' : 'gray.200';
      break;
  }

  return (
    <Box
      as="button"
      flex="1"
      height="60px"
      color={active ? 'brand.500' : 'gray.500'}
      onClick={onClick}
      _active={{ bg: 'white' }}
      {...borderProps}
    >
      <VStack spacing="1">
        {React.cloneElement(icon, { size: '24' })}
        <Text fontWeight="normal" fontSize="xs">
          {title}
        </Text>
      </VStack>
    </Box>
  );
};
