export function currency(number: number) {
  return number.toLocaleString('ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  });
}

export function thousandSeparator(number: number) {
  return number.toLocaleString('ID');
}
