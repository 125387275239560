import { Refine } from '@refinedev/core';

import { notificationProvider } from '@refinedev/chakra-ui';

import { OneFishProvider } from '@efishery/onefish';
import { EfisheryProvider } from '@efishery/sdk-reactjs';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { BrowserRouter } from 'react-router-dom';

import Maintenance from 'components/Maintenance';
import { ENV } from 'configs';
import { FlagContextProvider } from 'contexts';
import { fileServiceProvider } from 'features/file/dataProvider';
import { flagServiceProvider } from 'features/flag/dataProvider';
import { tmsServiceProvider } from 'features/transport/dataProvider';
import { authProvider } from 'provider';
import { customTitleHandler, sentry } from 'utils';
import { resources } from './resources';
import { AppRoutes } from './routes';

sentry.initialize();

function App() {
  return (
    <BrowserRouter>
      <EfisheryProvider
        clientId={ENV.CLIENT_ID}
        stage={ENV.STAGE}
        redirectUrl={`${window.location.origin}/redirect`}
        failedRedirectUrl={`${window.location.origin}/redirect`}
      >
        <OneFishProvider>
          <Refine
            dataProvider={{
              default: tmsServiceProvider,
              fms: fileServiceProvider,
              flagging_service: flagServiceProvider,
            }}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            resources={resources}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <FlagContextProvider>
              <Maintenance>
                <AppRoutes />
              </Maintenance>
            </FlagContextProvider>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={customTitleHandler} />
          </Refine>
        </OneFishProvider>
      </EfisheryProvider>
    </BrowserRouter>
  );
}

export default App;
