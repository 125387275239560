import { Button, Center, Heading, Spinner, VStack } from '@efishery/onefish';
import { SignOut } from '@onefish/icons-react';
import { useGetIdentity } from '@refinedev/core';
import { auth } from 'provider';
import { Card, Description } from 'components';
import { useCallback } from 'react';
import { DriverProfile } from './types';

export const ProfileList = () => {
  const profile = useGetIdentity<DriverProfile>();

  const handleSignOut = useCallback(async () => {
    await auth.logout();
    window.location.replace('/');
  }, []);

  if (!profile) {
    return (
      <Center h="100vh">
        <Spinner color="brand.500" />
      </Center>
    );
  }

  return (
    <VStack spacing={2}>
      <Card p="4">
        <Heading as="h2" size="md" mb={3} data-testid="txt_informasi_driver">
          Informasi Driver
        </Heading>
        <Description name="Nama driver" value={profile?.data?.name || '-'} />
        <Description
          name="Nomor HP"
          value={profile?.data?.phone_number || '-'}
        />
        <Description
          name="Jenis kendaraan"
          value={profile?.data?.fleet_name || '-'}
        />
        <Description
          name="Nomor kendaraan"
          value={profile?.data?.vehicle_license_plate || '-'}
        />
        <Description name="Vendor" value={profile?.data?.vendor_name || '-'} />
      </Card>

      {/* Future feature */}
      {/* <AttendanceForm profile={profile} profileRefetch={profileRefetch} /> */}

      <Center p="4">
        <Button
          data-testid="btn_signout"
          onClick={handleSignOut}
          variant="outline"
          leftIcon={<SignOut size="18" />}
        >
          Sign out
        </Button>
      </Center>
    </VStack>
  );
};
