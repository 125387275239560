import { useRef, useState } from 'react';
import {
  HttpError,
  useApiUrl,
  useCreate,
  useCustomMutation,
} from '@refinedev/core';
import { useFormContext } from 'react-hook-form';

import { Idle } from './Idle';
import { Loading } from './Loading';
import { Success } from './Success';
import { Error } from './Error';

import type { UploadResponse } from 'types';
import dayjs from 'dayjs';

type UploadImageProps = {
  title: string;
  name: string;
  fileName?: string;
  album?: string;
  bucket?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
};

export const UploadImage = ({
  title,
  name,
  fileName = 'attachment',
  album = 'driver-app',
  bucket = 'public-tools',
  isRequired = false,
  isDisabled = false,
}: UploadImageProps) => {
  const apiUrl = useApiUrl('fms');
  const inputRef = useRef<HTMLInputElement>(null);

  const { register, setValue, getValues, unregister } = useFormContext();

  register(name, {
    required: isRequired ? 'Tidak boleh kosong!' : false,
  });
  const initialImage = getValues(name);

  const [file, setFile] = useState<File | null>(null);

  const {
    mutate: mutateUpload,
    reset: resetUpload,
    status: mutationStatus,
    data,
  } = useCreate<UploadResponse[], HttpError>();

  const { mutate } = useCustomMutation();

  const imageUrl =
    (file && URL.createObjectURL(file)) ||
    data?.data[0].location ||
    initialImage;
  const imageKey = data?.data[0].key ?? getKey(imageUrl);

  const resetState = () => {
    unregister(name);
    resetUpload();
  };

  const generateFileName = (name: string) => {
    return `${dayjs().unix()}-${name}`;
  };

  const uploadFile = async (file: File) => {
    setFile(file);

    const payload = new FormData();
    payload.append('files', file);
    payload.append('name', generateFileName(fileName));
    payload.append('album', album);

    mutateUpload(
      {
        dataProviderName: 'fms',
        resource: 'upload',
        values: payload,
        meta: {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      },
      {
        onSuccess: response => {
          const imageUrl = response?.data[0].location;
          if (imageUrl) {
            setValue(name, imageUrl);
          }
        },
      },
    );
  };

  const deleteFile = () => {
    if (!imageKey) return;
    const payload = new URLSearchParams();
    payload.append('key', imageKey);
    payload.append('bucket', bucket);
    mutate(
      {
        dataProviderName: 'fms',
        url: `${apiUrl}/delete`,
        method: 'delete',
        values: payload,
        config: {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      },
      {
        onSuccess: resetState,
      },
    );
  };

  const handleClickUpload = () => {
    inputRef.current?.click();
  };

  const handleChangeFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    resetState();

    const file = files?.[0];

    // setTouched(true);
    if (!(file instanceof File)) return;
    uploadFile(file);
  };

  const handleClickCancel = () => {
    resetUpload();
  };

  const handleClickDelete = () => {
    resetUpload();
    deleteFile();
    setValue(name, '');
  };

  const handleClickRetry = () => {
    if (!file) return;
    resetUpload();
    uploadFile(file);
  };

  const status = initialImage ? 'success' : mutationStatus;

  switch (status) {
    case 'idle':
      return (
        <Idle
          ref={inputRef}
          title={title}
          onChangeFile={handleChangeFile}
          onClickUpload={handleClickUpload}
          isDisabled={isDisabled}
        />
      );

    case 'loading':
      return <Loading onClickCancel={handleClickCancel} />;

    case 'success':
      return (
        <Success
          imageUrl={imageUrl}
          onClickDelete={handleClickDelete}
          isDisabled={isDisabled}
        />
      );

    case 'error':
      return <Error onClickRetry={handleClickRetry} />;
  }
};

const getKey = (url: string, bucket = 'public-tools') => {
  const reUrlPath = /(?:\w+:)?\/\/[^/]+([^?#]+)/;
  const urlParts = url?.match(reUrlPath) || [url, undefined];
  const urlComponent = urlParts?.pop()?.slice(1);
  if (urlComponent) {
    const decoded = decodeURIComponent(urlComponent);
    const regex = new RegExp(`\\b${bucket}/\\b`, 'gi');
    const key = decoded.replace(regex, '');

    return key;
  }
};
