import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import { CaretRight } from '@onefish/icons-react';
import {
  HttpError,
  useGetIdentity,
  useInfiniteList,
  useNavigation,
} from '@refinedev/core';

import { Card, Empty, Error, FlagAccess, Loading } from 'components';

import { Order, TransportProfileDriverRes } from 'types';
import { composeDeliveryMessage, formatDeliveryStatus } from './helpers';
import { PAGE_SIZE } from './constants';
import { FLAG_ID } from 'configs';

export const DeliveryList = () => {
  const { edit } = useNavigation();
  const { data: driverIdentity } = useGetIdentity<TransportProfileDriverRes>();

  const {
    data: rawActiveDeliveries,
    isLoading: isLoadingActiveDeliveries,
    isError: isErrorActiveDeliveries,
    hasNextPage: hasNextPageActiveDeliveries,
    fetchNextPage: fetchNextPageActiveDeliveries,
    isFetchingNextPage: isFetchingNextPageActiveDeliveries,
  } = useInfiniteList<Order, HttpError>({
    resource: '/driver/shipping-order',
    pagination: {
      pageSize: PAGE_SIZE,
      mode: 'server',
    },
    queryOptions: {
      enabled: Boolean(driverIdentity?.id),
    },
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: 'AT_ORIGIN,INTRANSIT',
      },
      {
        field: 'shipment_type',
        operator: 'eq',
        value: 'LAST_MILE',
      },
    ],
  });

  const {
    data: rawWaitingDeliveries,
    isLoading: isLoadingWaitingDeliveries,
    isError: isErrorWaitingDeliveries,
    hasNextPage: hasNextPageWaitingDeliveries,
    fetchNextPage: fetchNextPageWaitingDeliveries,
    isFetchingNextPage: isFetchingNextPageWaitingDeliveries,
  } = useInfiniteList<Order, HttpError>({
    resource: '/driver/shipping-order',
    queryOptions: {
      enabled: Boolean(driverIdentity?.id),
    },
    pagination: {
      pageSize: PAGE_SIZE,
    },
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: 'READY_TO_ORIGIN',
      },
      {
        field: 'shipment_type',
        operator: 'eq',
        value: 'LAST_MILE',
      },
    ],
  });

  const activeDeliveries = rawActiveDeliveries?.pages.flatMap(
    page => page?.data ?? [],
  );
  const waitingDeliveries = rawWaitingDeliveries?.pages.flatMap(
    page => page?.data ?? [],
  );

  if (isLoadingActiveDeliveries || isLoadingWaitingDeliveries)
    return <Loading />;
  if (isErrorActiveDeliveries || isErrorWaitingDeliveries) return <Error />;
  if (!activeDeliveries?.length && !waitingDeliveries?.length)
    return <Empty message="Anda tidak memiliki antrian Pengiriman Barang" />;

  return (
    <FlagAccess
      variantKey="on"
      evaluation={{ flagID: FLAG_ID.ENABLE_DELIVERY }}
      fallback={<Empty message="Saat ini fitur tidak tersedia" />}
    >
      <Box px="3">
        <VStack spacing="10" alignItems="stretch">
          {activeDeliveries?.length && (
            <Box>
              <Heading as="h2" size="md" mb="3">
                Sedang Dilakukan
              </Heading>
              <VStack spacing="3" alignItems="stretch">
                {activeDeliveries?.map(delivery => (
                  <DeliveryItem
                    key={delivery.id}
                    delivery={delivery}
                    onClick={() => edit('delivery', delivery.id)}
                  />
                ))}
              </VStack>

              {hasNextPageActiveDeliveries && (
                <Center my="4">
                  <Button
                    variant="link"
                    fontSize="xs"
                    fontWeight="medium"
                    colorScheme="grey"
                    textDecoration="underline"
                    onClick={() => fetchNextPageActiveDeliveries()}
                    disabled={isFetchingNextPageActiveDeliveries}
                  >
                    {isFetchingNextPageActiveDeliveries
                      ? 'Sedang menampilkan lainnya...'
                      : 'Tampilkan lainnya'}
                  </Button>
                </Center>
              )}
            </Box>
          )}

          {waitingDeliveries?.length && (
            <Box>
              <Heading as="h2" size="md" mb="3">
                Menunggu Penjemputan
              </Heading>
              <VStack spacing="3" alignItems="stretch">
                {waitingDeliveries?.map(delivery => (
                  <DeliveryItem
                    key={delivery.id}
                    delivery={delivery}
                    onClick={() => edit('delivery', delivery.id)}
                  />
                ))}
              </VStack>

              {hasNextPageWaitingDeliveries && (
                <Center my="4">
                  <Button
                    variant="link"
                    fontSize="xs"
                    fontWeight="medium"
                    colorScheme="grey"
                    textDecoration="underline"
                    onClick={() => fetchNextPageWaitingDeliveries()}
                    disabled={isFetchingNextPageWaitingDeliveries}
                  >
                    {isFetchingNextPageWaitingDeliveries
                      ? 'Sedang menampilkan lainnya...'
                      : 'Tampilkan lainnya'}
                  </Button>
                </Center>
              )}
            </Box>
          )}
        </VStack>
      </Box>
    </FlagAccess>
  );
};

type DeliveryItemProps = {
  delivery: Order;
  onClick: () => void;
};

const DeliveryItem: React.FC<DeliveryItemProps> = ({
  delivery,
  onClick,
}: DeliveryItemProps) => {
  const { buttonLabel, statusColorScheme, statusLabel } = formatDeliveryStatus(
    delivery.status,
  );

  return (
    <Card>
      <Box p="3">
        <Flex justifyContent="flex-end" alignItems="center">
          <Badge colorScheme={statusColorScheme}>{statusLabel}</Badge>
        </Flex>
        <Text fontWeight="semibold" noOfLines={2} flex="1" mb="2">
          {delivery.status === 'READY_TO_ORIGIN'
            ? delivery.origin_name
            : delivery.dest_name}
        </Text>
        <Text fontSize="xs" color="grey.500" lineHeight="5">
          {composeDeliveryMessage(delivery, { showAddress: true })}
        </Text>
      </Box>

      <Box
        as="button"
        py="2"
        px="3"
        w="full"
        color="brand.500"
        borderTop="1px"
        borderTopColor="grey.100"
        bg="brand.50"
        onClick={onClick}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm" fontWeight="medium">
            {buttonLabel}
          </Text>
          <CaretRight size="20" />
        </Flex>
      </Box>
    </Card>
  );
};
