import {
  FormControl,
  FormLabel,
  HStack,
  FormErrorMessage,
  RadioGroup,
  Radio,
  InputGroup,
  NumberInput,
  NumberInputField,
  InputRightAddon,
  InputLeftAddon,
  useRadioGroup,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useFormContext,
} from 'react-hook-form';
import type { OrderDetail, OrderLine } from 'types';
import type { DamageState, ToSuccessFormValues } from '../../types';
import { RadioCard } from './RadioCard';
import { FISH_CONDITIONS } from '../constants';

type IsAcceptedState = '0' | '1';
type Props = {
  deliveryDetail: OrderDetail;
  orderLine: OrderLine;
  index: number;
};

type TotalDamageFormProps = {
  errors: FieldErrors<ToSuccessFormValues>;
  register: UseFormRegister<ToSuccessFormValues>;
  deliveryDetail: OrderDetail;
  orderLine: OrderLine;
  index: number;
};

type AdditionalFormProps = TotalDamageFormProps & {
  setValue: UseFormSetValue<ToSuccessFormValues>;
};

const TotalDamageForm = ({
  errors,
  register,
  orderLine,
  index,
}: TotalDamageFormProps) => {
  const damagedQty = Boolean(errors?.order_lines?.[index]?.damaged_quantity);

  return (
    <FormControl isInvalid={damagedQty} my="3">
      <FormLabel fontSize="sm">Total Ikan Kondisi Mati</FormLabel>
      <InputGroup>
        <NumberInput w="full">
          <NumberInputField
            borderEndRadius="none"
            type="number"
            {...register(`order_lines.${index}.damaged_quantity`, {
              required: 'Tidak boleh kosong!',
              max: {
                value: Number(orderLine.carried_quantity),
                message: 'Tidak boleh melebihi jumlah total yang dibawa',
              },
              min: {
                value: 0.1,
                message: 'Total ikan yang mati tidak boleh kosong',
              },
              valueAsNumber: true,
            })}
          />
        </NumberInput>
        <InputRightAddon children="kg" />
      </InputGroup>
      <FormErrorMessage>
        {errors?.order_lines?.[index]?.damaged_quantity?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

const AdditionalForm = ({
  errors,
  register,
  setValue,
  orderLine,
  index,
}: AdditionalFormProps) => {
  const [isAccepted, setIsAccepted] = useState<IsAcceptedState>('0');

  const { getValues } = useFormContext<ToSuccessFormValues>();

  const handleChange = (value: IsAcceptedState) => {
    setIsAccepted(value);
    if (value === '0') {
      setValue(`order_lines.${index}`, {
        ...getValues(`order_lines.${index}`),
        receive_damaged_quantity: 0,
        final_damaged_product_price: 0,
      });
    }
  };

  const receivedDamagedQty = Boolean(
    errors?.order_lines?.[index]?.receive_damaged_quantity,
  );
  const damagedProductPrice = Boolean(
    errors?.order_lines?.[index]?.final_damaged_product_price,
  );

  return (
    <>
      <FormControl my="2">
        <HStack>
          <FormLabel fontSize="sm" m="auto 0">
            Yang mati diambil / tidak?
          </FormLabel>
          <RadioGroup defaultValue="0" onChange={handleChange}>
            <HStack>
              <Radio id="accepted-dead-fish" value="1">
                Ya
              </Radio>
              <Radio id="rejected-dead-fish" value="0">
                Tidak
              </Radio>
            </HStack>
          </RadioGroup>
        </HStack>
      </FormControl>

      {isAccepted === '1' && (
        <>
          <FormControl isInvalid={receivedDamagedQty}>
            <FormLabel fontSize="sm">Jumlah yang diambil</FormLabel>
            <InputGroup>
              <NumberInput w="full">
                <NumberInputField
                  borderEndRadius="none"
                  {...register(
                    `order_lines.${index}.receive_damaged_quantity`,
                    {
                      required: 'Tidak boleh kosong!',
                      max: {
                        value: Number(orderLine.carried_quantity),
                        message:
                          'Tidak boleh melebihi jumlah total yang dibawa',
                      },
                      min: {
                        value: 0.1,
                        message: 'Jumlah yang diambil wajib diisi',
                      },
                      valueAsNumber: true,
                    },
                  )}
                />
              </NumberInput>
              <InputRightAddon children="kg" />
            </InputGroup>
            <FormErrorMessage>
              {errors?.order_lines?.[index]?.receive_damaged_quantity?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={damagedProductPrice}>
            <FormLabel fontSize="sm">Harga</FormLabel>
            <InputGroup>
              <InputLeftAddon children="Rp" />
              <NumberInput w="full" precision={2}>
                <NumberInputField
                  borderStartRadius="none"
                  {...register(
                    `order_lines.${index}.final_damaged_product_price`,
                    {
                      required: 'Tidak boleh kosong!',
                      valueAsNumber: true,
                    },
                  )}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>
              {
                errors?.order_lines?.[index]?.final_damaged_product_price
                  ?.message
              }
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </>
  );
};

export const FishConditionForm = ({
  deliveryDetail,
  orderLine,
  index,
}: Props) => {
  const { register, formState, setValue, getValues, clearErrors } =
    useFormContext<ToSuccessFormValues>();

  const { errors } = formState;
  const values = getValues();

  const renderDamageForm = () => {
    if (values.order_lines[index].damage_state === 'NONE') return null;
    if (values.order_lines[index].damage_state === 'PARTIAL')
      return (
        <>
          <TotalDamageForm
            errors={errors}
            register={register}
            deliveryDetail={deliveryDetail}
            orderLine={orderLine}
            index={index}
          />
          <AdditionalForm
            errors={errors}
            register={register}
            deliveryDetail={deliveryDetail}
            orderLine={orderLine}
            setValue={setValue}
            index={index}
          />
        </>
      );

    if (values.order_lines[index].damage_state === 'ALL')
      return (
        <AdditionalForm
          errors={errors}
          register={register}
          deliveryDetail={deliveryDetail}
          orderLine={orderLine}
          setValue={setValue}
          index={index}
        />
      );
    return null;
  };

  function handleChange(value: DamageState) {
    const damagedQuantity: Record<DamageState, number> = {
      ALL: orderLine.carried_quantity,
      PARTIAL: 0, // zero first then let the user decide
      NONE: 0,
    };

    // Resetting every order line value on damage state change
    setValue(`order_lines.${index}`, {
      ...getValues(`order_lines.${index}`),
      receive_good_quantity: orderLine.carried_quantity,
      receive_damaged_quantity: 0,
      final_good_product_price: orderLine.original_price,
      final_damaged_product_price: 0,
      damaged_quantity: damagedQuantity[value],
      damage_state: value,
    });

    clearErrors(`order_lines.${index}`);
  }

  const { value, getRadioProps } = useRadioGroup({
    onChange: handleChange,
  });

  const isDamagedInvalid = !['NONE', 'PARTIAL', 'ALL'].includes(
    value as string,
  );

  return (
    <>
      <FormControl isInvalid={isDamagedInvalid}>
        <FormLabel>
          Apakah Ikan {orderLine.product_name} diterima dengan kondisi baik
          semua?
        </FormLabel>
        <HStack justifyContent="space-between">
          {FISH_CONDITIONS.map(condition => {
            const radio = getRadioProps({ value: condition.value });
            return (
              <RadioCard
                key={`${condition.value}-${index}`}
                id={condition.value}
                value={condition.value}
                index={index}
                {...radio}
              >
                {condition.label}
              </RadioCard>
            );
          })}
        </HStack>
        <FormErrorMessage>
          {errors.order_lines?.[index]?.damage_state?.message}
        </FormErrorMessage>
      </FormControl>
      {renderDamageForm()}
    </>
  );
};
