import { useEffect } from 'react';
import { Card, Description } from 'components';
import type { OrderDetail } from 'types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Box, Button, Divider, Heading, Text, VStack } from '@efishery/onefish';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';

import { Address } from './Address';
import { composeDeliveryMessage, transformSuccessPayload } from '../helpers';
import { formatShipDate } from 'pages/pickup/helpers';
import { FishConditionForm } from './FishConditionForm';
import { UnloadingUploadForm } from './UnloadingUploadForm';
import { DELIVERY_OPTIONS } from '../constants';
import type {
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from '../../types';

type OnDeliveryProps = {
  deliveryDetail: OrderDetail;
};

export const OnDelivery = ({ deliveryDetail }: OnDeliveryProps) => {
  const { mutate, isLoading } = useCreate<
    BaseRecord,
    HttpError,
    ToSuccessTransformedFormValues
  >();
  const invalidate = useInvalidate();

  const methods = useForm<ToSuccessFormValues, HttpError, ToSuccessFormValues>({
    defaultValues: {
      order_lines: deliveryDetail.order_lines.map(orderLine => ({
        id: orderLine.id,
        receive_good_quantity: orderLine.carried_quantity,
        receive_damaged_quantity: 0,
        final_good_product_price: orderLine.original_price,
        final_damaged_product_price: 0,
        damaged_quantity: 0,
        carried_quantity: orderLine.carried_quantity,
        damage_state: undefined,
      })),
    },
  });

  const { setValue, handleSubmit } = methods;

  useEffect(() => {
    setValue('attachments.0', {
      link: '',
      type: 'RECEIVING_REPORT',
    });
    setValue('attachments.1', {
      link: '',
      type: 'PRODUCT_CONDITION',
    });
    setValue('attachments.2', {
      link: '',
      type: 'BUYER_ACCEPTANCE',
    });
    setValue('attachments.3', {
      link: '',
      type: 'PROOF_OF_PAYMENT',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateToNextStatus: SubmitHandler<ToSuccessFormValues> = formValues => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/delivery-success`,
        values: transformSuccessPayload(formValues),
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Selesai`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <VStack
        w="full"
        alignItems="stretch"
        spacing="3"
        mb="6"
        as="form"
        onSubmit={handleSubmit(updateToNextStatus)}
      >
        <Card p="4">
          <VStack w="full" alignItems="stretch" spacing="3">
            <Heading as="h2" size="md">
              Bongkar Barang
            </Heading>

            <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
              <Box>
                <Text fontWeight="semibold">{deliveryDetail.dest_name}</Text>
              </Box>
            </Box>

            <Text fontSize="md" lineHeight="5" color="grey.500">
              {composeDeliveryMessage(deliveryDetail)}
            </Text>
          </VStack>
        </Card>

        <Address
          address={deliveryDetail.dest_address}
          city={deliveryDetail.dest_city_name}
          province={deliveryDetail.dest_province_name}
        />

        <Card p="4">
          <Description
            name="Tanggal pengantaran"
            value={formatShipDate(deliveryDetail.delivery_at)}
          />
        </Card>

        {deliveryDetail.order_lines.map((orderLine, index) => {
          return (
            <Card p="4" key={index}>
              <VStack w="full" alignItems="stretch" spacing="2">
                <Description name="Jenis Ikan" value={orderLine.product_name} />
                <Description
                  name="Ukuran Ikan"
                  value={orderLine.product_name}
                />

                <Divider variant="dashed" />

                <Description
                  name="Jumlah yang Dibawa"
                  value={orderLine.carried_quantity}
                  format="thousand-separator"
                  unit="kg"
                />

                <Divider variant="dashed" />

                <Description
                  name="Harga"
                  value={new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2,
                  }).format(orderLine.original_price)}
                />

                <Description
                  name="Total"
                  value={new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2,
                  }).format(orderLine.total_original_price)}
                />

                <Divider variant="dashed" />

                <FishConditionForm
                  deliveryDetail={deliveryDetail}
                  orderLine={orderLine}
                  index={index}
                />
              </VStack>
            </Card>
          );
        })}

        <Card p="4">
          <Description
            name="Grand Total"
            value={new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 2,
            }).format(deliveryDetail.total_original_price)}
          />
        </Card>

        <UnloadingUploadForm />

        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          loadingText="Memperbarui status..."
        >
          {
            DELIVERY_OPTIONS[DELIVERY_OPTIONS[deliveryDetail.status].nextStatus]
              .label
          }
        </Button>
      </VStack>
    </FormProvider>
  );
};
