import { ResourceProps } from '@refinedev/core';
import { Package, Petugas, Trophy, Truck } from '@onefish/icons-react';

export const resources: ResourceProps[] = [
  {
    name: 'profile',
    list: '/profile',
    meta: {
      label: 'Profile',
      icon: <Petugas />,
      bottomNav: true,
    },
  },
  {
    name: 'pickup',
    list: '/pickup',
    edit: '/pickup/edit/:id',
    meta: {
      label: 'Inbound',
      headerTitle: 'Informasi Pengiriman Barang',
      icon: <Truck />,
      bottomNav: true,
    },
  },
  {
    name: 'delivery',
    list: '/delivery',
    edit: '/delivery/edit/:id',
    meta: {
      label: 'Outbound',
      headerTitle: 'Informasi Pengiriman Barang',
      icon: <Package />,
      bottomNav: true,
    },
  },
  {
    name: 'finish',
    list: '/finish',
    show: '/finish/show/:id',
    meta: {
      label: 'Selesai',
      icon: <Trophy />,
      bottomNav: true,
    },
  },
];
