import type { OrderStatus, ShipmentType } from 'types';

export type InboundShipmentType = Exclude<ShipmentType, 'LAST_MILE'>;

export const PAGE_SIZE = 4;

export const PICKUP_OPTIONS: {
  [K in OrderStatus]: {
    label: string;
    colorScheme: string;
    nextStatus: OrderStatus;
  };
} = {
  READY_TO_ORIGIN: {
    label: '',
    colorScheme: 'grey',
    nextStatus: 'AT_ORIGIN',
  },
  AT_ORIGIN: {
    label: 'Muat Barang',
    colorScheme: 'yellow',
    nextStatus: 'INTRANSIT',
  },
  INTRANSIT: {
    label: 'Lanjut Kirim Barang',
    colorScheme: 'orange',
    nextStatus: 'DELIVERY_SUCCESS',
  },
  DELIVERY_SUCCESS: {
    label: 'Selesaikan Pengiriman',
    colorScheme: 'observatory',
    nextStatus: 'DELIVERY_SUCCESS',
  },
};

export const PICKUP_TYPES: {
  [K in InboundShipmentType]: {
    label: string;
    colorScheme: string;
  };
} = {
  FIRST_MILE: {
    label: 'Pickup',
    colorScheme: 'red',
  },
  MID_MILE: {
    label: 'Shuttle',
    colorScheme: 'purple',
  },
};
