import { Box } from '@efishery/onefish';
import { useParsed } from '@refinedev/core';

import { Empty, FlagAccess, Loading } from 'components';
import type { OrderDetail } from 'types';

import { DeliveryDraft, DeliveryLoading, OnDelivery } from './components';
import { Navigate } from 'react-router-dom';
import { FLAG_ID } from 'configs';
import { HttpError, useOne } from '@refinedev/core';

export const DeliveryEdit = () => {
  const { id } = useParsed();

  const { data, isLoading, isError } = useOne<OrderDetail, HttpError>({
    resource: 'driver/shipping-order',
    id,
  });

  const order = data?.data;

  if (isLoading) return <Loading />;
  if (isError || !order) return <Empty />;

  let rendered = null;

  switch (order.status) {
    case 'READY_TO_ORIGIN':
      rendered = <DeliveryDraft deliveryDetail={order} />;
      break;
    case 'AT_ORIGIN':
      rendered = <DeliveryLoading deliveryDetail={order} />;
      break;
    case 'INTRANSIT':
      rendered = <OnDelivery deliveryDetail={order} />;
      break;
    case 'DELIVERY_SUCCESS':
      rendered = (
        <Navigate
          to={`/finish/show/${order.id}`}
          state={{
            finish: true,
          }}
        />
      );
      break;
    default:
      rendered = <Navigate to={`/delivery`} />;
      break;
  }

  return (
    <FlagAccess
      variantKey="on"
      evaluation={{ flagID: FLAG_ID.ENABLE_DELIVERY }}
      fallback={<Empty message="Saat ini fitur tidak tersedia" />}
    >
      <Box px="3">{rendered}</Box>
    </FlagAccess>
  );
};
