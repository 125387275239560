import { forwardRef } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  VisuallyHiddenInput,
} from '@efishery/onefish';
import { Image as ImageIcon } from '@onefish/icons-react';
import { toKebabCase } from 'utils';

type IdleProps = {
  title?: string;
  onChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickUpload: () => void;
  isDisabled?: boolean;
};

type Ref = React.Ref<HTMLInputElement>;

export const Idle = forwardRef(
  (
    { title, onChangeFile, onClickUpload, isDisabled }: IdleProps,
    _ref: Ref,
  ) => {
    return (
      <Box
        py="3"
        px="4"
        border="1px"
        borderColor="grey.200"
        borderStyle="dashed"
        borderRadius="lg"
      >
        <VStack w="full" spacing="4">
          <Box color="grey.500">
            <ImageIcon size="40" weight="fill" />
          </Box>

          <Box fontSize="sm" textAlign="center">
            <Text fontWeight="medium">Upload foto {title}</Text>
            <Text color="grey.500">
              Foto yang di upload dalam bentuk JPG atau PNG, dengan ukuran max.
              10MB
            </Text>
          </Box>

          <VisuallyHiddenInput
            ref={_ref}
            type="file"
            name="image-upload"
            accept="image/*"
            onChange={onChangeFile}
            {...(title ? { id: toKebabCase(title ?? '') } : {})}
          />
          <Button
            variant="outline"
            width="full"
            onClick={onClickUpload}
            isDisabled={isDisabled}
          >
            Upload Foto
          </Button>
        </VStack>
      </Box>
    );
  },
);
