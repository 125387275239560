import type { DataProvider } from '@refinedev/core';
import { SERVICES } from 'configs';
import { CreateHttp } from 'utils';

const apiUrl = SERVICES.FLAGGING;
const httpClient = CreateHttp(apiUrl);

export const flagServiceProvider: Partial<DataProvider> = {
  create: async ({ resource, variables }) => {
    return await httpClient.post(resource, variables).then(r => {
      return r;
    });
  },
  getApiUrl: () => {
    return apiUrl;
  },
};
