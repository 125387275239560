import { DeliveryStatus, ShipmentType } from 'types';

export const DELIVERY_PAGE_SIZE = 10;
export const DELIVERY_OPTIONS: {
  [K in DeliveryStatus]: {
    label: string;
    colorScheme: string;
    nextStatus: DeliveryStatus;
  };
} = {
  DRAFT: {
    label: 'Antrian',
    colorScheme: 'grey',
    nextStatus: 'LOADING',
  },
  LOADING: {
    label: 'Muat Barang',
    colorScheme: 'yellow',
    nextStatus: 'ON_DELIVERY',
  },
  ON_DELIVERY: {
    label: 'Lanjut Kirim Barang',
    colorScheme: 'orange',
    nextStatus: 'UNLOADING',
  },
  UNLOADING: {
    label: 'Bongkar Barang',
    colorScheme: 'red',
    nextStatus: 'FINISH',
  },
  FINISH: {
    label: 'Selesaikan Pengiriman',
    colorScheme: 'observatory',
    nextStatus: 'FINISH',
  },
};

export const SHIPPING_ORDER_TYPES: {
  [K in ShipmentType]: string;
} = {
  FIRST_MILE: 'Pickup',
  MID_MILE: 'Shuttle',
  LAST_MILE: 'Delivery',
};
