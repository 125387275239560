import { Box } from '@efishery/onefish';
import { HttpError, useOne, useParsed } from '@refinedev/core';

import { Empty, Loading } from 'components';
import type { OrderDetail } from 'types';

import { PickupDraft, PickupLoading, PickupOnDelivery } from './components';
import { Navigate } from 'react-router-dom';

export const PickupEdit = () => {
  const { id } = useParsed();

  const { data, isLoading, isError } = useOne<OrderDetail, HttpError>({
    resource: 'driver/shipping-order',
    id,
  });

  const order = data?.data;

  if (isLoading) return <Loading />;
  if (isError || !order) return <Empty />;

  let rendered = null;

  switch (order.status) {
    case 'READY_TO_ORIGIN':
      rendered = <PickupDraft deliveryDetail={order} />;
      break;
    case 'AT_ORIGIN':
      rendered = <PickupLoading deliveryDetail={order} />;
      break;
    case 'INTRANSIT':
      rendered = <PickupOnDelivery deliveryDetail={order} />;
      break;
    case 'DELIVERY_SUCCESS':
      rendered = (
        <Navigate
          to={`/finish/show/${order.id}`}
          state={{
            finish: true,
          }}
        />
      );
      break;
    default:
      rendered = <Navigate to={`/pickup`} />;
      break;
  }

  return <Box px="3">{rendered}</Box>;
};
