import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import { CaretRight } from '@onefish/icons-react';
import {
  HttpError,
  useGetIdentity,
  useInfiniteList,
  useNavigation,
} from '@refinedev/core';

import { Card, Empty, Error, Loading } from 'components';

import { formatEta } from 'pages/pickup/helpers';
import { Order, TransportProfileDriverRes } from 'types';
import { startCase } from 'utils';
import { DELIVERY_PAGE_SIZE, SHIPPING_ORDER_TYPES } from './constants';

export const FinishList = () => {
  const { show } = useNavigation();
  const { data: driverIdentity } = useGetIdentity<TransportProfileDriverRes>();

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteList<Order, HttpError>({
    resource: '/driver/shipping-order',
    queryOptions: {
      enabled: Boolean(driverIdentity?.fleet_id),
    },
    pagination: {
      pageSize: DELIVERY_PAGE_SIZE,
    },
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: 'DELIVERY_SUCCESS',
      },
      {
        field: 'shipment_type',
        operator: 'eq',
        value: 'FIRST_MILE,MID_MILE,LAST_MILE',
      },
      {
        field: 'sort',
        operator: 'eq',
        value: '-expected_delivery_at',
      },
    ],
  });

  const finishDeliveries = data?.pages.flatMap(page => page.data ?? []);

  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  if (!finishDeliveries?.length)
    return (
      <Empty message="Anda belum memiliki pengiriman yang sudah selesai" />
    );

  return (
    <Box px="3">
      <VStack spacing="10" alignItems="stretch">
        {finishDeliveries?.length && (
          <Box>
            <Heading as="h2" size="md" mb="3">
              Pengiriman Selesai
            </Heading>
            <VStack spacing="3" alignItems="stretch">
              {finishDeliveries?.map(delivery => (
                <DeliveryItem
                  key={delivery.so_external_id}
                  delivery={delivery}
                  onClick={() => show('finish', delivery.id)}
                />
              ))}
            </VStack>

            {hasNextPage && (
              <Center my="4">
                <Button
                  variant="link"
                  fontSize="xs"
                  fontWeight="medium"
                  colorScheme="grey"
                  textDecoration="underline"
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? 'Sedang menampilkan lainnya...'
                    : 'Tampilkan lainnya'}
                </Button>
              </Center>
            )}
          </Box>
        )}
      </VStack>
    </Box>
  );
};

type DeliveryItemProps = {
  delivery: Order;

  onClick: () => void;
};

const DeliveryItem: React.FC<DeliveryItemProps> = ({
  delivery,
  onClick,
}: DeliveryItemProps) => {
  const {
    dest_name,
    expected_delivery_at,
    expected_delivery_end_at,
    so_external_id,
    shipment_type,
    id,
  } = delivery;
  const { etaDate, etaTimeStart, etaTimeEnd } = formatEta(
    expected_delivery_at,
    expected_delivery_end_at,
  );

  return (
    <Card>
      <Box p="3">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight="semibold" noOfLines={2} flex="1">
            {so_external_id}
          </Text>
          <Badge colorScheme="brand.500">
            {SHIPPING_ORDER_TYPES[shipment_type]}
          </Badge>
        </Flex>
        <Text noOfLines={2} flex="1">
          {startCase(dest_name)}
        </Text>
        <Text fontSize="xs" color="grey.500" lineHeight="5">
          Pengiriman tanggal <em>{etaDate}</em> jam{' '}
          <em>
            {etaTimeStart} - {etaTimeEnd}
          </em>
        </Text>
      </Box>

      <Box
        as="button"
        py="2"
        px="3"
        w="full"
        color="brand.500"
        borderTop="1px"
        borderTopColor="grey.100"
        bg="brand.50"
        onClick={onClick}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontSize="sm"
            fontWeight="medium"
            data-testid={'btn_shipping_order_detail_' + id}
          >
            Lihat detail
          </Text>
          <CaretRight size="20" />
        </Flex>
      </Box>
    </Card>
  );
};
