import dayjs from 'dayjs';

import { Order, OrderDetail, OrderStatus } from 'types';
import { formatDateTimeRange, toTitleCase } from 'utils';

import { PICKUP_OPTIONS } from './constants';
import { getAttachmentLink } from 'utils/order';
import { ToIntransitFormValues } from 'pages/types';

export const formatShipDate = (ship_date?: string, format = 'D MMM YYYY') => {
  return ship_date ? dayjs.utc(ship_date).local().format(format) : '';
};

type MessageOption = {
  showAddress?: boolean;
};

/**
 *  Convert eta date from API response with UTC time to local time
 *  The time returned can be used for displaying eta date in UI or for payload data
 * @param eta_date_start
 * @param eta_date
 * @param date_format required - default to D MMM YYYY
 * @param time_format required - default to D MMM YYYY
 * @returns
 */
export const formatEta = (
  eta_date_start?: string,
  eta_date?: string,
  date_format = 'D MMM YYYY',
  time_format = 'HH:mm',
) => {
  const etaDate = eta_date
    ? dayjs.utc(eta_date).local().format(date_format)
    : '';

  const etaTimeStart = eta_date_start
    ? dayjs.utc(eta_date_start).local().format(time_format)
    : '';

  const etaTimeEnd = eta_date
    ? dayjs.utc(eta_date).local().format(time_format)
    : '';

  return {
    etaDate,
    etaTimeStart,
    etaTimeEnd,
  };
};

export const formatPickupStatus = (status: OrderStatus) => {
  const pickupStatus = PICKUP_OPTIONS[status];

  return {
    buttonLabel: PICKUP_OPTIONS[pickupStatus.nextStatus].label,
    statusColorScheme: pickupStatus.colorScheme,
    statusLabel: pickupStatus.label,
  };
};

export const composePickupMessage = (
  order: Order | OrderDetail,
  { showAddress = false }: MessageOption = {},
) => {
  const { dest_name, expected_delivery_at, expected_delivery_end_at, status } =
    order;

  const subdistrict = toTitleCase(order?.dest_subdistrict_name ?? '');
  const district = toTitleCase(order?.dest_district_name ?? '');
  const city = toTitleCase(order?.dest_city_name ?? '');

  const address = `${order.dest_address} ${subdistrict}, ${district}, ${city}`;

  switch (status) {
    case 'READY_TO_ORIGIN':
    case 'AT_ORIGIN':
      return (
        <>
          Untuk pengiriman ke <strong>{dest_name}</strong>
          <br />
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Hub{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
    case 'INTRANSIT':
    case 'DELIVERY_SUCCESS':
      return (
        <>
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Hub{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
  }
};

export const getLoadingDefaultValues = (
  deliveryDetail: OrderDetail,
): ToIntransitFormValues => {
  return {
    attachments: [
      {
        link:
          getAttachmentLink(deliveryDetail.attachments, 'CONSIGNMENT_NOTE') ??
          '',
        type: 'CONSIGNMENT_NOTE',
      },
    ],
    order_lines: deliveryDetail.order_lines.map(orderLine => ({
      id: orderLine.id,
      carried_quantity: orderLine.quantity,
    })),
  };
};
