import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Card, Description, UploadImage } from 'components';
import type { OrderDetail } from 'types';
import { formatShipDate } from 'pages/pickup/helpers';
import { getAttachmentLink } from 'utils/order';

import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@efishery/onefish';
import { ArrowSquareDown } from '@onefish/icons-react';

import { composeDeliveryMessage } from '../helpers';
import { DELIVERY_OPTIONS } from '../constants';
import { Address } from './Address';
import type { ToIntransitFormValues } from '../../types';

type DeliveryLoadingProps = {
  deliveryDetail: OrderDetail;
};

export const DeliveryLoading = ({ deliveryDetail }: DeliveryLoadingProps) => {
  const methods = useForm<
    ToIntransitFormValues,
    HttpError,
    ToIntransitFormValues
  >();
  const { mutate, isLoading } = useCreate<
    BaseRecord,
    HttpError,
    ToIntransitFormValues
  >();
  const invalidate = useInvalidate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue(
      'order_lines',
      deliveryDetail.order_lines.map(orderLine => ({
        id: orderLine.id,
        carried_quantity: orderLine.quantity,
      })),
    );
    setValue('attachments.0', {
      link:
        getAttachmentLink(deliveryDetail.attachments, 'CONSIGNMENT_NOTE') ?? '',
      type: 'CONSIGNMENT_NOTE',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateToNextStatus: SubmitHandler<
    ToIntransitFormValues
  > = formValues => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/intransit`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Lanjut Kirim Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <VStack
        w="full"
        alignItems="stretch"
        spacing="3"
        mb="6"
        as="form"
        onSubmit={handleSubmit(updateToNextStatus)}
      >
        <Card p="4">
          <VStack w="full" alignItems="stretch" spacing="3">
            <Heading as="h2" size="md">
              Muat Barang
            </Heading>

            <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
              <Box>
                <Text fontWeight="semibold">{deliveryDetail.origin_name}</Text>
                <HStack>
                  <ArrowSquareDown weight="fill" />
                  <Text fontSize="sm">Muat barang</Text>
                </HStack>
              </Box>
            </Box>

            <Text fontSize="md" lineHeight="5" color="grey.500">
              {composeDeliveryMessage(deliveryDetail)}
            </Text>
          </VStack>
        </Card>

        <Address
          address={deliveryDetail.dest_address}
          city={deliveryDetail.dest_city_name}
          province={deliveryDetail.dest_province_name}
        />

        <Card p="4">
          <Description
            name="Tanggal Penjemputan"
            value={formatShipDate(deliveryDetail.delivery_at)}
          />
        </Card>

        {deliveryDetail.order_lines.map((orderLine, index) => {
          return (
            <Card p="4" key={orderLine.id}>
              <VStack w="full" alignItems="stretch" spacing="2">
                <Description name="Jenis Ikan" value={orderLine.product_name} />
                <Description
                  name="Ukuran Ikan"
                  value={orderLine.product_name}
                />

                <Divider variant="dashed" />

                <Description
                  name="Total Tonase yang Akan Dibawa"
                  value={orderLine.quantity}
                  format="thousand-separator"
                  unit="kg"
                />

                <Divider variant="dashed" />

                <FormControl
                  isInvalid={Boolean(
                    errors?.order_lines?.[index]?.carried_quantity,
                  )}
                >
                  <FormLabel fontSize="sm">Total Ikan Dibawa</FormLabel>
                  <InputGroup>
                    <NumberInput w="full" precision={2}>
                      <NumberInputField
                        borderEndRadius="none"
                        {...register(`order_lines.${index}.carried_quantity`, {
                          required: 'Tidak boleh kosong!',
                          valueAsNumber: true,
                        })}
                      />
                    </NumberInput>
                    <InputRightAddon children="kg" />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.order_lines?.[index]?.carried_quantity?.message}
                  </FormErrorMessage>
                </FormControl>

                <Description
                  name="Total Harga"
                  value={new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2,
                  }).format(orderLine.original_price)}
                />
              </VStack>
            </Card>
          );
        })}

        <Card p="4">
          <Description
            name="Grand Total"
            value={new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 2,
            }).format(deliveryDetail.total_original_price)}
          />
        </Card>

        <Card p="4">
          <FormControl isInvalid={Boolean(errors?.attachments?.[0]?.link)}>
            <FormLabel fontSize="sm">Surat Jalan</FormLabel>
            <UploadImage
              title="surat jalan"
              name="attachments.0.link"
              fileName="consignment_note"
              isRequired
            />
            <FormErrorMessage>
              {errors.attachments?.[0]?.link?.message}
            </FormErrorMessage>
          </FormControl>
        </Card>

        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          loadingText="Memperbarui status..."
        >
          {
            DELIVERY_OPTIONS[DELIVERY_OPTIONS[deliveryDetail.status].nextStatus]
              .label
          }
        </Button>
      </VStack>
    </FormProvider>
  );
};
