import { Box, Text, UseRadioProps, useRadio } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import type { ToSuccessFormValues } from '../../types';

type RadioCardProps = {
  children: React.ReactNode;
  index: number;
} & UseRadioProps;

export const RadioCard = (props: RadioCardProps) => {
  const { register } = useFormContext<ToSuccessFormValues>();
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  const getColor = () => {
    if (props.value === 'ALL') return 'red';
    if (props.value === 'NONE') return 'brand';
    return 'yellow';
  };

  return (
    <Box as="label">
      <input
        {...register(`order_lines.${props.index}.damage_state`, {
          required: 'Pilih salah satu!',
        })}
        {...input}
        hidden
      />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        bgColor={`${getColor()}.200`}
        _checked={{
          bg: `${getColor()}.500`,
          color: 'white',
        }}
        px={5}
        py={3}
        id={`fish-condition-${props.id}`}
      >
        <Text fontSize="sm">{props.children}</Text>
      </Box>
    </Box>
  );
};
