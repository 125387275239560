import React from 'react';
import { Box } from '@chakra-ui/react';
import { AppBar as DefaultAppBar } from './AppBar';
import { NavBar as DefaultNavBar } from './NavBar';

type MobileLayoutProps = {
  AppBar?: () => JSX.Element;
  BottomNavBar?: () => JSX.Element;
  children?: React.ReactNode;
};

export const MobileLayout = ({
  AppBar,
  BottomNavBar,
  children,
}: MobileLayoutProps) => {
  const AppBarToRender = AppBar ?? DefaultAppBar;
  const BottomNavBarToRender = BottomNavBar ?? DefaultNavBar;

  return (
    <Box bg="grey.50">
      <Box
        maxW="md"
        minH="100vh"
        marginX="auto"
        display="flex"
        flexDirection="column"
      >
        <AppBarToRender />
        <Box py="4" flex="1">
          {children}
        </Box>
        <BottomNavBarToRender />
      </Box>
    </Box>
  );
};
