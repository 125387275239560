import React from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import { MapPinLine } from '@onefish/icons-react';

import { Card, Description } from 'components';
import type { OrderDetail } from 'types';

import { Address } from './Address';
import { DELIVERY_OPTIONS } from 'pages/delivery/constants';
import { composePickupMessage, formatShipDate } from '../helpers';

type PickupDraftProps = {
  deliveryDetail: OrderDetail;
};

export const PickupDraft = ({ deliveryDetail }: PickupDraftProps) => {
  const isShuttle = deliveryDetail?.shipment_type === 'MID_MILE';

  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();
  const invalidate = useInvalidate();

  const updateToNextStatus = () => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/at-origin`,
        values: {},
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Muat Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return (
    <VStack w="full" alignItems="stretch" spacing="3" mb="6">
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Heading as="h2" size="md">
            Menunggu Penjemputan
          </Heading>

          <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box>
              <Text fontWeight="semibold">{deliveryDetail.origin_name}</Text>
              <HStack>
                <MapPinLine />
                <Text fontSize="sm">Sampai di lokasi penjemputan</Text>
              </HStack>
            </Box>
          </Box>

          <Text fontSize="md" lineHeight="5" color="grey.500">
            {composePickupMessage(deliveryDetail)}
          </Text>
        </VStack>
      </Card>

      <Address
        address={deliveryDetail.dest_address}
        city={deliveryDetail.dest_city_name}
        province={deliveryDetail.dest_province_name}
      />

      <Card p="4">
        <Description name="Status Penjemputan" value="-" />
      </Card>

      <Card p="4">
        <Description
          name={isShuttle ? 'Tanggal Pengiriman' : 'Tanggal Penjemputan'}
          value={formatShipDate(deliveryDetail.delivery_at)}
        />
      </Card>

      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="2">
          {deliveryDetail.order_lines.map((orderLine, index) => {
            return (
              <React.Fragment key={orderLine.id}>
                <Description name="Jenis Ikan" value={orderLine.product_name} />
                <Description
                  name="Ukuran Ikan"
                  value={orderLine.product_name}
                />

                <Description
                  name="Total Tonase yang Akan Dibawa"
                  value={orderLine.quantity}
                  format="thousand-separator"
                  unit="kg"
                />

                {index !== deliveryDetail.order_lines.length - 1 && (
                  <Divider variant="dashed" />
                )}
              </React.Fragment>
            );
          })}
        </VStack>
      </Card>

      <Button
        width="full"
        onClick={updateToNextStatus}
        isLoading={isLoading}
        loadingText="Memperbarui status..."
      >
        {
          DELIVERY_OPTIONS[DELIVERY_OPTIONS[deliveryDetail.status].nextStatus]
            .label
        }
      </Button>
    </VStack>
  );
};
