import { useFlag, type UseFlagParams } from 'hooks';

export type FlagAccessProps = UseFlagParams & {
  fallback?: React.ReactNode;
  children?: React.ReactNode;
};

export const FlagAccess = ({
  fallback,
  children,
  ...useFlagParams
}: FlagAccessProps) => {
  const { data: can, isLoading } = useFlag(useFlagParams);

  if (can && !isLoading) {
    return children;
  }

  return fallback ?? null;
};
