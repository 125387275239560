import { useCreate, type HttpError } from '@refinedev/core';
import { PropsWithChildren, createContext, useCallback } from 'react';
import type { Flagr, FlagrEvaluation } from 'types';
import { ENV } from 'configs';

export type CanParams = {
  variantKey: string;
  evaluation: FlagrEvaluation;
};

export type FlagContextType = {
  can?: (params: CanParams) => Promise<boolean>;
  isLoading: boolean;
};

export const FlagContext = createContext<FlagContextType>({
  can: undefined,
  isLoading: false,
});

export const FlagContextProvider = ({ children }: PropsWithChildren) => {
  const { mutateAsync, isLoading } = useCreate<
    Flagr,
    HttpError,
    FlagrEvaluation
  >();

  const can = useCallback(async ({ variantKey, evaluation }: CanParams) => {
    const { flagKey, ...restFlagEvaluation } = evaluation;
    const { data } = await mutateAsync({
      dataProviderName: 'flagging_service',
      errorNotification: false,
      successNotification: false,
      resource: 'evaluation',
      values: {
        flagKey: flagKey ?? ENV.STAGE,
        ...restFlagEvaluation,
      },
      meta: {
        headers: {
          'content-type': 'application/json',
        },
      },
    });

    return data.variantKey === variantKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlagContext.Provider value={{ can, isLoading }}>
      {children}
    </FlagContext.Provider>
  );
};
