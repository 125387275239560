import type { OrderStatus } from 'types';
import type { DamageState } from '../types';

export const PAGE_SIZE = 4;

export const DELIVERY_OPTIONS: {
  [K in OrderStatus]: {
    label: string;
    colorScheme: string;
    nextStatus: OrderStatus;
  };
} = {
  READY_TO_ORIGIN: {
    label: '',
    colorScheme: 'grey',
    nextStatus: 'AT_ORIGIN',
  },
  AT_ORIGIN: {
    label: 'Muat Barang',
    colorScheme: 'yellow',
    nextStatus: 'INTRANSIT',
  },
  INTRANSIT: {
    label: 'Lanjut Kirim Barang',
    colorScheme: 'orange',
    nextStatus: 'DELIVERY_SUCCESS',
  },
  DELIVERY_SUCCESS: {
    label: 'Selesaikan Pengiriman',
    colorScheme: 'observatory',
    nextStatus: 'DELIVERY_SUCCESS',
  },
};

export const FISH_CONDITIONS: {
  label: string;
  value: DamageState;
  colorScheme: string;
}[] = [
  {
    label: 'Ya, aman',
    value: 'NONE',
    colorScheme: 'brand',
  },
  {
    label: 'Tidak, ada mati',
    value: 'PARTIAL',
    colorScheme: 'yellow',
  },
  {
    label: 'Mati semua',
    value: 'ALL',
    colorScheme: 'red',
  },
];
