import {
  Box,
  Divider,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from '@efishery/onefish';
import { MapPinLine } from '@onefish/icons-react';

import { Card, Description } from 'components';
import type { ShipmentType, OrderDetail } from 'types';
import { startCase } from 'utils';

import { formatEta, formatShipDate } from 'pages/pickup/helpers';
import { Address } from './Address';
import { getAttachmentLink } from 'utils/order';

type ShippingOrderProps = {
  deliveryDetail?: OrderDetail;
};

const isShowReceivingReport = (type?: ShipmentType) => {
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};
const isShowProofOfPayment = (type?: ShipmentType) => {
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};
const isShowProductCondition = (type?: ShipmentType) => {
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};
const isShowBuyerAcceptance = (type?: ShipmentType) => {
  if (type === 'LAST_MILE') {
    return true;
  }
  return false;
};

export const ShippingOrder = ({ deliveryDetail }: ShippingOrderProps) => {
  const { etaDate, etaTimeStart, etaTimeEnd } = formatEta(
    deliveryDetail?.expected_delivery_at,
    deliveryDetail?.expected_delivery_end_at,
  );

  const isShuttle = deliveryDetail?.shipment_type === 'MID_MILE';

  // react strict mode workaround
  const proofOfPayment = getAttachmentLink(
    deliveryDetail?.attachments ?? null,
    'PROOF_OF_PAYMENT',
  );
  const consignmentNote = getAttachmentLink(
    deliveryDetail?.attachments ?? null,
    'CONSIGNMENT_NOTE',
  );
  const receivingReport = getAttachmentLink(
    deliveryDetail?.attachments ?? null,
    'RECEIVING_REPORT',
  );
  const productCondition = getAttachmentLink(
    deliveryDetail?.attachments ?? null,
    'PRODUCT_CONDITION',
  );
  const buyerAcceptance = getAttachmentLink(
    deliveryDetail?.attachments ?? null,
    'BUYER_ACCEPTANCE',
  );

  return (
    <VStack w="full" alignItems="stretch" spacing="3" mb="6">
      <Card p="4">
        <VStack w="full" alignItems="stretch" spacing="3">
          <Heading as="h2" size="md">
            Detail Pengiriman
          </Heading>

          <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
            <Box>
              <Text fontWeight="semibold">
                Diantarkan ke {startCase(deliveryDetail?.dest_name) || '-'}
              </Text>
              <HStack>
                <MapPinLine />
                <Text fontSize="sm">Selesai</Text>
              </HStack>
            </Box>
          </Box>

          <Text fontSize="md" lineHeight="5" color="grey.500">
            Pengiriman tanggal <strong>{etaDate}</strong> jam{' '}
            <em>
              {etaTimeStart} - {etaTimeEnd}
            </em>
          </Text>
        </VStack>
      </Card>

      <Address
        address={deliveryDetail?.dest_address}
        city={deliveryDetail?.dest_city_name}
        province={deliveryDetail?.dest_province_name}
      />

      <Card p="4">
        <Description
          name="Tanggal Pengantaran"
          value={formatShipDate(deliveryDetail?.delivery_success_at ?? '')}
        />
      </Card>

      {(deliveryDetail?.order_lines ?? []).map(orderLine => {
        return (
          <>
            <Card p="4">
              <VStack w="full" alignItems="stretch" spacing="2">
                <Description
                  name="Jenis Ikan"
                  value={orderLine?.product_name}
                />
                <Description
                  name="Ukuran Ikan"
                  value={orderLine?.product_uom}
                />

                <Divider variant="dashed" />

                <Description
                  name="Jumlah yang Dibawa"
                  value={orderLine?.carried_quantity}
                  format="thousand-separator"
                  unit="kg"
                />

                {!isShuttle && (
                  <>
                    <Divider variant="dashed" />

                    <Description
                      name="Total Ikan diterima dengan kondisi baik"
                      value={orderLine.receive_good_quantity}
                      format="thousand-separator"
                      unit="kg"
                    />

                    <Description
                      name="Total Ikan diterima dengan kondisi kurang baik"
                      value={orderLine.receive_damaged_quantity}
                      format="thousand-separator"
                      unit="kg"
                    />

                    <Divider variant="dashed" />

                    <Description
                      name="Harga Ikan dengan kondisi baik"
                      value={new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 2,
                      }).format(orderLine.good_product_final_price as number)}
                    />

                    <Description
                      name="Harga Ikan dengan kondisi kurang baik"
                      value={new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 2,
                      }).format(
                        orderLine.damaged_product_final_price as number,
                      )}
                    />

                    <Divider variant="dashed" />

                    <Description
                      name="Total Harga Ikan kondisi baik"
                      value={
                        new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 2,
                        }).format(
                          orderLine.total_good_product_final_price as number,
                        ) || '-'
                      }
                    />

                    <Description
                      name="Total Harga Ikan kondisi kurang baik"
                      value={
                        new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 2,
                        }).format(
                          orderLine.total_damaged_product_final_price as number,
                        ) || '-'
                      }
                    />

                    <Divider variant="dashed" />

                    <Description
                      name="Sub Total"
                      value={new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 2,
                      }).format(
                        ((orderLine?.total_good_product_final_price as number) ??
                          0) +
                          ((orderLine?.total_damaged_product_final_price as number) ??
                            0),
                      )}
                    />
                  </>
                )}
              </VStack>
            </Card>
          </>
        );
      })}

      <Card p="4">
        <Description
          name="Grand Total"
          value={new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 2,
          }).format(deliveryDetail?.total_final_price as number)}
        />
      </Card>

      {deliveryDetail?.origin_name && (
        <Card p="4">
          <VStack w="full" alignItems="stretch" spacing="3">
            <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
              <Box>
                <Text fontWeight="semibold">
                  Ambil Ikan dari {startCase(deliveryDetail.origin_name)}
                </Text>
              </Box>
            </Box>
          </VStack>
        </Card>
      )}

      <Card p="4">
        <Text fontSize="md" fontWeight="bold">
          Surat Jalan
        </Text>
        {(consignmentNote ?? '').length > 0 ? (
          <Image src={consignmentNote} alt={deliveryDetail?.order_id} />
        ) : (
          <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
            Tidak ada Surat Jalan
          </Box>
        )}
      </Card>

      {isShowReceivingReport(deliveryDetail?.shipment_type) && (
        <Card p="4">
          <Text fontSize="md" fontWeight="bold">
            Surat Receiving Report (RR)
          </Text>
          {(receivingReport ?? '').length > 0 ? (
            <Image src={receivingReport} alt={deliveryDetail?.order_id} />
          ) : (
            <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
              Tidak ada Surat Receiving Report
            </Box>
          )}
        </Card>
      )}

      {isShowProductCondition(deliveryDetail?.shipment_type) && (
        <Card p="4">
          <Text fontSize="md" fontWeight="bold">
            Bukti Kondisi Produk
          </Text>
          {(productCondition ?? '').length > 0 ? (
            <Image src={productCondition} alt={deliveryDetail?.order_id} />
          ) : (
            <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
              Tidak ada Bukti Kondisi Produk
            </Box>
          )}
        </Card>
      )}

      {isShowBuyerAcceptance(deliveryDetail?.shipment_type) && (
        <Card p="4">
          <Text fontSize="md" fontWeight="bold">
            Bukti Penerimaan Pembeli
          </Text>
          {(buyerAcceptance ?? '').length > 0 ? (
            <Image src={buyerAcceptance} alt={deliveryDetail?.order_id} />
          ) : (
            <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
              Tidak ada Bukti Penerimaan Pembeli
            </Box>
          )}
        </Card>
      )}

      {isShowProofOfPayment(deliveryDetail?.shipment_type) && (
        <Card p="4">
          <Text fontSize="md" fontWeight="bold">
            Bukti Transfer
          </Text>
          {(proofOfPayment ?? '').length > 0 ? (
            <Image src={proofOfPayment} alt={deliveryDetail?.order_id} />
          ) : (
            <Box borderWidth="1px" borderRadius="lg" p={4} mt={2} color="gray">
              Tidak ada bukti transfer
            </Box>
          )}
        </Card>
      )}
    </VStack>
  );
};
