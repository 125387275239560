import { Box } from '@efishery/onefish';
import { HttpError, useOne, useParsed } from '@refinedev/core';

import { Empty } from 'components';
import type { OrderDetail } from 'types';
import { ShippingOrder } from './components';

export const FinishShow = () => {
  const { id } = useParsed();

  const { data } = useOne<OrderDetail, HttpError>({
    resource: '/driver/shipping-order',
    id,
  });

  const deliveryDetails = data?.data;

  if (!deliveryDetails) return <Empty />;

  return (
    <Box as="form" px="3">
      <ShippingOrder deliveryDetail={deliveryDetails} />
    </Box>
  );
};
