import type { OrderStatus, Order, OrderDetail } from 'types';
import type {
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from '../types';
import { formatDateTimeRange, toTitleCase } from 'utils';
import { DELIVERY_OPTIONS } from './constants';

type MessageOption = {
  showAddress?: boolean;
};

export const composeDeliveryMessage = (
  delivery: Order | OrderDetail,
  { showAddress = false }: MessageOption = {},
) => {
  const { dest_name, expected_delivery_at, expected_delivery_end_at, status } =
    delivery;

  const subdistrict = toTitleCase(delivery?.dest_subdistrict_name ?? '');
  const district = toTitleCase(delivery?.dest_district_name ?? '');
  const city = toTitleCase(delivery?.dest_city_name ?? '');

  const address = `${delivery.dest_address} ${subdistrict}, ${district}, ${city}`;

  switch (status) {
    case 'READY_TO_ORIGIN':
      return (
        <>
          Untuk pengiriman ke <strong>{dest_name}</strong>
          <br />
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
    case 'AT_ORIGIN':
    case 'INTRANSIT':
    case 'DELIVERY_SUCCESS':
      return (
        <>
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
  }
};

export const formatDeliveryStatus = (status: OrderStatus) => {
  const deliveryStatus = DELIVERY_OPTIONS[status];

  return {
    buttonLabel: DELIVERY_OPTIONS[deliveryStatus.nextStatus].label,
    statusColorScheme: deliveryStatus.colorScheme,
    statusLabel: deliveryStatus.label,
  };
};

export function calcTotalPrice(quantity: number, price: number) {
  return quantity * price;
}

export const transformSuccessPayload = (
  formValues: ToSuccessFormValues,
): ToSuccessTransformedFormValues => {
  const attachments = formValues.attachments?.filter(
    attachment => attachment.link,
  );
  const orderLines = formValues.order_lines.map(orderLine => ({
    id: orderLine.id,
    receive_good_quantity:
      orderLine.carried_quantity - (orderLine.damaged_quantity ?? 0),
    receive_damaged_quantity: orderLine.receive_damaged_quantity,
    final_good_product_price: orderLine.final_good_product_price,
    final_damaged_product_price: orderLine.final_damaged_product_price,
  }));

  return { attachments, order_lines: orderLines };
};
