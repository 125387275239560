import { AuthBindings } from '@refinedev/core';
import { Auth, Stage } from '@efishery/sdk-core';
import nookies from 'nookies';
import { ENV } from 'configs';
import { sentry } from 'utils';
import { CustomProfileResponse } from 'types';

export const auth = new Auth(
  ENV.CLIENT_ID,
  Stage[ENV.STAGE as keyof typeof Stage],
);

export const authProvider: AuthBindings = {
  login: async ({ grantType, code }) => {
    const { accessToken } = await auth.persistExchange(code, grantType);
    await auth.getProfile(accessToken);

    return {
      success: true,
      redirectTo: '/',
    };
  },
  logout: async () => {
    await auth.logout();
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async ctx => {
    const accessTokenKey = auth.getAccessTokenKey();
    const cookies = nookies.get(ctx);
    if (!cookies[accessTokenKey]) {
      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => null,
  getIdentity: async ctx => {
    const accessTokenKey = auth.getAccessTokenKey();
    const cookies = nookies.get(ctx);

    // should only fetch profile if access token in cookie exists
    if (accessTokenKey in cookies) {
      const profile = await auth.getProfile(cookies[accessTokenKey]);

      const profileData = profile?.data as unknown as CustomProfileResponse;

      if (profileData?.data) {
        sentry.setUser(profileData?.data);
      }
      return profileData?.data ?? null;
    }
    return null;
  },
  onError: async error => {
    console.error(error);
    return { error };
  },
};
