import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
} from '@efishery/onefish';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';

import { Card, Description, UploadImage } from 'components';
import { OrderDetail } from 'types';

import { composePickupMessage, formatShipDate } from '../helpers';
import { Address } from './Address';
import { DELIVERY_OPTIONS } from 'pages/delivery/constants';
import { transformSuccessPayload } from 'pages/delivery/helpers';
import type { ToSuccessFormValues } from '../../types';

type PickupUnloadingProps = {
  deliveryDetail: OrderDetail;
};

export const PickupOnDelivery = ({ deliveryDetail }: PickupUnloadingProps) => {
  const isShuttle = deliveryDetail?.shipment_type === 'MID_MILE';

  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();
  const invalidate = useInvalidate();

  const methods = useForm<ToSuccessFormValues, HttpError, ToSuccessFormValues>({
    defaultValues: {
      order_lines: deliveryDetail.order_lines.map(orderLine => ({
        id: orderLine.id,
        receive_good_quantity: orderLine.carried_quantity,
        receive_damaged_quantity: 0,
        final_good_product_price: orderLine.original_price,
        final_damaged_product_price: 0,
        damaged_quantity: 0,
        carried_quantity: orderLine.carried_quantity,
        damage_state: undefined,
      })),
    },
  });

  const { setValue, handleSubmit } = methods;

  useEffect(() => {
    setValue('attachments.0', {
      link: '',
      type: 'RECEIVING_REPORT',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateToNextStatus: SubmitHandler<ToSuccessFormValues> = formValues => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/delivery-success`,
        values: transformSuccessPayload(formValues),
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Selesai`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <VStack
        w="full"
        alignItems="stretch"
        spacing="3"
        mb="6"
        as="form"
        onSubmit={handleSubmit(updateToNextStatus)}
      >
        <Card p="4">
          <VStack w="full" alignItems="stretch" spacing="3">
            <Heading as="h2" size="md">
              Bongkar Barang
            </Heading>

            <Box bg="brand.70" borderRadius="md" p="3" color="brand.600">
              <Box>
                <Text fontWeight="semibold">{deliveryDetail.dest_name}</Text>
              </Box>
            </Box>

            <Text fontSize="md" lineHeight="5" color="grey.500">
              {composePickupMessage(deliveryDetail)}
            </Text>
          </VStack>
        </Card>

        <Address
          address={deliveryDetail.dest_address}
          city={deliveryDetail.dest_city_name}
          province={deliveryDetail.dest_province_name}
        />

        <Card p="4">
          <Description name="Status Penjemputan" value="Lanjut kirim barang" />
        </Card>

        <Card p="4">
          <Description
            name="Tanggal pengantaran"
            value={formatShipDate(deliveryDetail.delivery_at)}
          />
        </Card>

        {deliveryDetail.order_lines?.map(orderLine => (
          <Card p="4" key={orderLine.id}>
            <VStack w="full" alignItems="stretch" spacing="2">
              <Description name="Jenis Ikan" value={orderLine.product_name} />
              <Description name="Ukuran Ikan" value={orderLine.product_name} />

              <Divider variant="dashed" />

              <Description
                name="Jumlah yang Dibawa"
                value={orderLine.carried_quantity}
                format="thousand-separator"
                unit="kg"
              />
            </VStack>
          </Card>
        ))}

        {!isShuttle && (
          <Card p="4">
            <FormControl>
              <FormLabel fontSize="sm">Surat Receiving Report (RR)</FormLabel>
              <UploadImage
                title="surat receiving report (RR)"
                name="attachments.0.link"
                fileName="receive_receipt"
              />
            </FormControl>
          </Card>
        )}

        <Button
          width="full"
          type="submit"
          isLoading={isLoading}
          loadingText="Memperbarui status..."
        >
          {
            DELIVERY_OPTIONS[DELIVERY_OPTIONS[deliveryDetail.status].nextStatus]
              .label
          }
        </Button>
      </VStack>
    </FormProvider>
  );
};
