import {
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { UploadImage } from 'components';
import type { ToSuccessFormValues } from '../../types';

export const UnloadingUploadForm = () => {
  const { formState } = useFormContext<ToSuccessFormValues>();
  const { errors } = formState;

  return (
    <Card p="4">
      <FormControl isInvalid={Boolean(errors?.attachments?.[0]?.link)}>
        <FormLabel fontSize="sm">Surat Receiving Report (RR)</FormLabel>
        <UploadImage
          title="surat receiving report (RR)"
          name="attachments.0.link"
          fileName="receive_receipt"
          isRequired
        />
        <FormErrorMessage>
          {errors.attachments?.[0]?.link?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors?.attachments?.[1]?.link)}>
        <FormLabel fontSize="sm">Foto Kondisi Ikan</FormLabel>
        <UploadImage
          title="kondisi ikan"
          name="attachments.1.link"
          fileName="product_condition"
          isRequired
        />
        <FormErrorMessage>
          {errors.attachments?.[1]?.link?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors?.attachments?.[2]?.link)}>
        <FormLabel fontSize="sm">Penerimaan oleh Pembeli</FormLabel>
        <UploadImage
          title="penerimaan oleh pembeli"
          name="attachments.2.link"
          fileName="buyer_acceptance"
          isRequired
        />
        <FormErrorMessage>
          {errors.attachments?.[2]?.link?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors?.attachments?.[3]?.link)}>
        <FormLabel fontSize="sm">Bukti Transfer</FormLabel>
        <UploadImage
          title="bukti transfer"
          name="attachments.3.link"
          fileName="proof_of_paymnent"
        />
        <FormErrorMessage>
          {errors.attachments?.[3]?.link?.message}
        </FormErrorMessage>
      </FormControl>
    </Card>
  );
};
